import { Chat } from './chat.js';
import ReactDOM from 'react-dom';
import React from 'react';

const APPS = {
    // Chat,
};

// var Messager = <Chat />;
// self.Messager = Messager;
self.Messager = ReactDOM.render(<Chat />, document.getElementById('messager-container'));

// function renderAppInElement(el) {
//     var appName = el.id.substr('react_'.length);
//     var App = APPS[appName];
//
//     if (!App) return;
//
//     // get props from elements data attribute, like the post_id
//
//     let output = {};
//
//     let json = el.attributes['data-json'].value;
//     const props = Object.assign({}, JSON.parse(json));
//     ReactDOM.render(<App {...props} />, el);
// }
//
// document
//     .querySelectorAll('.__react-root')
//     .forEach(renderAppInElement)