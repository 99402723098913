import React from 'react';

export class Chat extends React.Component {

    changeState(object) {
        this.setState(Object.assign({}, this.state, object));
    }

    toggle(){

        if(this.opened){
            $('#messager').css({
                height: '35px',
                top: 'unset',
            });
        }else{
            $('#messager').css({
                height: '350px',
                top: 'unset',
            });
        }

        this.opened = !this.opened;
    }

    selectUser(id) {
        const $this = this;

        var found = $this.state.chats.find(function (item) {
            return item.id == id;
        });
        if (found == null) {
            Socket.socket.emit('loadChat', {'id': id});
        } else {
            Socket.socket.emit('selectUser', {'id': id});

            // Убираем hasNew у чата и проставляем текущего пользователя
            let newState = $this.state;
            let chatIndex = newState.chats.indexOf(found);
            newState.chats[chatIndex].hasNew = false;
            newState.currentUser = id;
            newState.historyEnd = false;

            $this.changeState(newState);
        }
    }

    constructor(props) {

        super(props);

        this.state = {
            display: false,
            defaultHeight: '300px',
            opened: false,
            currentUser: null,
            messages: [],
            chats: [],
            containerStyles: {

            },
            historyEnd: false,
        }

        this.opened = true;
        this.lastMessage = null;

        this.changeState = this.changeState.bind(this);
        this.selectUser = this.selectUser.bind(this);
        this.toggle = this.toggle.bind(this);
        this.loadHistory = this.loadHistory.bind(this);
    }

    componentDidMount() {

        console.log('chat mounted');

        const $this = this;

        // ====================================
        // Инициализация проекта по данным
        // ====================================
        function init() {

            console.log('chat start initialization');

            // ==================================
            // Нода даёт чаты на загрузку
            // ==================================
            Socket.socket.on('loadChats', (data) => {
                console.log('receive chats', data);
                $this.changeState({chats: data});
            })

            // ==================================
            // Нода даёт чаты на загрузку
            // ==================================
            Socket.socket.on('loadChat', (data) => {
                let newState = $this.state;
                newState.chats.push(data);
                $this.setState(newState);
                if (data.loadConversation) {
                    $this.selectUser(data.id);
                }
            });

            // ==================================
            // Нода даёт историю на подгрузку
            // ==================================
            Socket.socket.on('loadHistory', (data) => {
                var newState = Object.assign({}, $this.state);
                if(data.length < 10){
                    newState.historyEnd = true;
                }
                data = data.reverse();

                for(var item of data){
                    newState.messages.unshift(item);
                }

                $this.changeState(newState);
            });

            // ==================================
            // Нода говорить загрузить сообщения с пользователем
            // ==================================
            Socket.socket.on('loadConversation', function (data) {
                console.log('load conversation event received', data);
                $this.changeState({messages: data});
                $this.messagesEnd.scrollTop = $this.messagesEnd.scrollHeight;
            })

            // ==================================
            // Исходящее сообщение
            // ==================================
            Socket.socket.on('fromMeMessage', function (data) {
                let newState = $this.state;
                data.isMy = 'to';
                newState.messages.push(data);
                $this.setState(newState);
                $this.messagesEnd.scrollTop = $this.messagesEnd.scrollHeight;
            });

            // ==================================
            // Входящее сообщение
            // ==================================
            Socket.socket.on('toMeMessage', function (data) {

                console.log('toMeMessage', data);


                let fromUserId = data.source_person_id;

                // Если текущий пользователь на связи
                if (fromUserId == $this.state.currentUser) {
                    let newState = $this.state;
                    data.isMy = 'from';
                    newState.messages.push(data);
                    $this.setState(newState);
                    $this.messagesEnd.scrollTop = $this.messagesEnd.scrollHeight;
                } else {
                    // Если этот пользователь не включён, то ищем в чатах его
                    let found = $this.state.chats.find(function (item) {
                        return item.id == fromUserId;
                    });

                    if (found == null) { // Этот пользователь ещё не писал нам - надо загрузить чат
                        Socket.socket.emit('loadChat', {'id': fromUserId, 'loadConversation': false});
                    } else {
                        // пользователь уже писал нам.. давай поставим галочку, что он писал
                        let newState = $this.state;
                        let index = newState.chats.indexOf(found);
                        newState.chats[index].hasNew = true;
                        $this.setState(newState);
                    }
                }
            });

            // первоначальная загрузка чатов
            Socket.socket.emit('loadChats', {});

        }

        // ====================================
        // Проверка гоновности сокета к работе (ожидание ready)
        // ====================================
        Socket.subscribeForReady( (authorized) => {

            init();

            if(authorized){
                console.log('authorized', authorized);
                $this.changeState({display: true});

                // some jquery
                $('#messager').resizable({
                    handles: 'n, e, ne',
                });

                if(this.opened){
                    $('#messager').css({
                        height: '35px',
                        top: 'unset',
                    });
                }else{
                    $('#messager').css({
                        height: '350px',
                        top: 'unset',
                    });
                }
            }
        });
    }

    loadHistory(){
        if(this.state.messages.length > 0){
            let firstMessage = this.state.messages[0];
            Socket.socket.emit('loadHistory', {'id': this.state.currentUser, 'lastId': firstMessage.id});
        }

    }

    render() {

        const $this = this;
        const {messages, chats} = this.state;

        // ====================================
        // Выбор пользователя для чата
        // ====================================
        let clickChat = (id) => {
            $this.selectUser(id);
        };

        // ====================================
        // Отправить сообщение
        // ====================================
        let clickSend = () => {
            let value = $this.input.value;
            $this.input.value = '';
            Socket.socket.emit('sendMessage', {'id': $this.state.currentUser, 'message': value});
        };
        let inputKeyPress = (e) => {
            if (e.key === 'Enter') {
                clickSend();
            }
        }

        // ====================================
        // Загрузка истории сообщений текущего пользователя
        // ====================================
        let loadHistory = () => {
            this.loadHistory();
        };


        // ====================================
        // Вывод сообщений
        // ====================================
        let messagesList = [];
        messages.forEach((message, index) => {
            let className = "message message-" + message.isMy;
            messagesList.push(
                <div key={index} className={className}>
                    <div>{message.text}</div>
                </div>
            );
        });
        if (messagesList.length == 0) {
            messagesList = <div>Выберите пользователя для того, чтобы написать ему сообщение</div>;
        }

        // ====================================
        // Вывод списка чатов
        // ====================================
        let chatsList = [];
        chats.forEach((chat, index) => {

            let isAdmin = chat.isAdmin == '' ? '' : <b>{chat.isAdmin}</b>;
            let className = ['user-list-user'];
            const chat_id = chat.id;

            if (chat.id == $this.state.currentUser)
                className.push('selected');

            let element = <div onClick={ () => {
                clickChat(chat_id)
            } } key={index} className={className.join(" ")}>
                <div className="img"></div>
                <div className="user-name">
                    {chat.shortName} {isAdmin}
                    { chat.hasNew && <span className="fa fa fa-envelope-open-o" style={{color: 'red'}}></span> }
                </div>
            </div>;

            chatsList.push(element);
        });

        let hasNew = chatsList.find( (item) => { return item.hasNew } ) != null;

        let loadMore = '';
        console.log('loadMore?', $this.state.currentUser, !$this.state.historyEnd)
        if($this.state.currentUser != null && !$this.state.historyEnd){
            loadMore = <div onClick={ this.loadHistory }>Загрузить предыдущие</div>;
        }

        // ====================================
        // render
        // ====================================
        if(!this.state.display){
            return null;
        }
        return (
            <div id="messager" style={ $this.state.containerStyles }>
                <div className="header" onClick={ $this.toggle }>
                    Сообщения
                    {hasNew && <span className="fa fa fa-envelope-open-o" style={{color: 'green', 'font-size': '16px'}}> </span>}
                </div>
                <div className="container">
                    <div className="user-list">
                        {chatsList}
                    </div>
                    <div className="messages-wrapper">
                        <div className="messages-box" ref={(el) => {
                            $this.messagesEnd = el;
                        }}>
                            {loadMore}
                            {messagesList}
                        </div>
                    </div>
                    <div className="messages-form">
                        <input onKeyPress={ inputKeyPress } ref={(el) => {
                            $this.input = el;
                        }} type="text"/><span onClick={ clickSend } className="fa fa-paper-plane-o"> </span>
                    </div>
                </div>
            </div>
        )
    }
}